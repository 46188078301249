import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Features/PTZ/SuccessBox';
import PTZTable01 from 'components/Web_User_Interface/1080p_Series/Features/PTZ/ptz01Table';
import PTZTable02 from 'components/Web_User_Interface/1080p_Series/Features/PTZ/ptz02Table';
import PTZTable03 from 'components/Web_User_Interface/1080p_Series/Features/PTZ/ptz03Table';
import PTZTable04 from 'components/Web_User_Interface/1080p_Series/Features/PTZ/ptz04Table';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Features Menu // Pan, Tilt & Zoom",
  "path": "/Web_User_Interface/1080p_Series/Features/PTZ/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Deactivate your camera´s calibration run at start-up (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit the range of the pan & tilt motion for privacy concerns.",
  "image": "./WebUI_1080p_SearchThumb_Features_PTZ.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Features_PTZ.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Features Menu // Pan, Tilt & Zoom' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Deactivate your camera´s calibration run at start-up (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit the range of the pan & tilt motion for privacy concerns.' image='/images/Search/WebUI_1080p_SearchThumb_Features_PTZ.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Features_PTZ.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Features/PTZ/' locationFR='/fr/Web_User_Interface/1080p_Series/Features/PTZ/' crumbLabel="Pan, Tilt & Zoom" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "features-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features-menu",
        "aria-label": "features menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`Pan & Tilt Function`}</strong>{`
`}<em parentName="p">{`only in Pan, Tilt & Zoom Cameras`}</em></p>
    <h3 {...{
      "id": "indoor-cameras-zb-in-8015-full-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#indoor-cameras-zb-in-8015-full-hd",
        "aria-label": "indoor cameras zb in 8015 full hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Indoor Cameras (z.B. IN-8015 Full HD)`}</h3>
    <h4 {...{
      "id": "positions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#positions",
        "aria-label": "positions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Positions`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/144f4c2fe3f1064cb1f72c1a598dd9eb/0931d/1080p_Settings_Features_PTZ_Positions.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACwUlEQVQoz32Ra0hTYRjHj34pw1K8VJaZukynm5ekRTo1neWmIah56cNymdfCqLlzebednfecbUJoiPStG4iEhi68dAHJCAq/Rn5PKghJshRi2XbOeeKciUQf+vF+eF54fvzf53kJiqJoFQYh1sXQ3tGe0dfdI69sw4vtw4vtQ8qxDb3svfPW7rvLupDHgy93dOTp8guLigmSYkiKoShaELy1FnNjc9u3X/BlU/qw+nPla/DTemhlbevzeuiHCIHJSfqS1WG12eoa60+Vlmh1hINkBkiaoii/32csq6g2mQFAFkOyHAaQlVoGkKUgwNKT8Xs3+gY7ne76i0xNeW1eIUHRSjJNR5Jr7P1dAPA7FJIVaRtZkmSAwINHA+etI71dw103zRXW/LyTiuxQkmnB67OcPdNrbQCAra2tcDgc8WUASRQBYGx05Bl1YZmveDd6ZcLl6CwvIBBCJK3MHHl2ZWW1JEvBYFAUw6IoyrIEIIXFMAAsBB721BW1FWtvN6a/6UubtuYQDIMiC/P7/UZjmdmszqwCAN+D8HETQqtrsLFxf3zsQEqGNkuXnJRSkJrgMCQSDpIm1c/y+bxVVSadTj83P/94KrC89PzF09m52ZnpmYWpwVvvxyeuX+0/cjAl69jx+Ph98bExpvQYgkGIQUhdmGCxmAmCiIqK1scT2mSlbMqJW26K69QnGpubtSUGTbbmUEZqzJ7dsbuiT6dEKTMjVcYYt7a2xCXsry0+2nUuY2/S4fwsDafNRVkaky7XUFvecK2tsbvFYCnVn8jP1uWkZWZuyxFYlsUYe3nMcZjDWMCcn8MkxyHW43GzPMY+vw/zGGPMcRyPuX9lQeAx5iMoTTwWeF7YuSoOjnRgjAmnEzkRcjoV2a0mc/h/cCqRmrCTtIOi7SRN0ozb7XYph3WzrEdlp+9v2ePxsCzrcrn+AEV6rKtTb5fNAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/144f4c2fe3f1064cb1f72c1a598dd9eb/e4706/1080p_Settings_Features_PTZ_Positions.avif 230w", "/en/static/144f4c2fe3f1064cb1f72c1a598dd9eb/d1af7/1080p_Settings_Features_PTZ_Positions.avif 460w", "/en/static/144f4c2fe3f1064cb1f72c1a598dd9eb/7f308/1080p_Settings_Features_PTZ_Positions.avif 920w", "/en/static/144f4c2fe3f1064cb1f72c1a598dd9eb/f056e/1080p_Settings_Features_PTZ_Positions.avif 1373w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/144f4c2fe3f1064cb1f72c1a598dd9eb/a0b58/1080p_Settings_Features_PTZ_Positions.webp 230w", "/en/static/144f4c2fe3f1064cb1f72c1a598dd9eb/bc10c/1080p_Settings_Features_PTZ_Positions.webp 460w", "/en/static/144f4c2fe3f1064cb1f72c1a598dd9eb/966d8/1080p_Settings_Features_PTZ_Positions.webp 920w", "/en/static/144f4c2fe3f1064cb1f72c1a598dd9eb/082bf/1080p_Settings_Features_PTZ_Positions.webp 1373w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/144f4c2fe3f1064cb1f72c1a598dd9eb/81c8e/1080p_Settings_Features_PTZ_Positions.png 230w", "/en/static/144f4c2fe3f1064cb1f72c1a598dd9eb/08a84/1080p_Settings_Features_PTZ_Positions.png 460w", "/en/static/144f4c2fe3f1064cb1f72c1a598dd9eb/c0255/1080p_Settings_Features_PTZ_Positions.png 920w", "/en/static/144f4c2fe3f1064cb1f72c1a598dd9eb/0931d/1080p_Settings_Features_PTZ_Positions.png 1373w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/144f4c2fe3f1064cb1f72c1a598dd9eb/c0255/1080p_Settings_Features_PTZ_Positions.png",
              "alt": "Web User Interface - 1080p Series - Features PTZ",
              "title": "Web User Interface - 1080p Series - Features PTZ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PTZTable01 mdxType="PTZTable01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{` Here you can disable the cameras calibration run during start-up (please note that this also deactivates your saved positions!), send it to saved position 1 after a reboot and set the speed and number of auto-scans for the pan & tilt movement when you use the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Live_Video/"
      }}>{`HSCAN or VSCAN`}</a>{`.`}</p>
    <p>{`The Pan & Tilt Limits will be displayed next to the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Live_Video/"
      }}>{`PTZ Control Pad`}</a>{`. You can use them to limit the range of movement of your camera (see below).`}</p>
    <h4 {...{
      "id": "extended",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#extended",
        "aria-label": "extended permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Extended`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/396c62abea0556e4984de649ae1a6f49/bb27a/1080p_Settings_Features_PTZ_Advanced.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACyElEQVQoz3WRWUhUURjH7zxEq4kaZlOKaFPmTKOYSRjhMjkujeMCFUpWOGkqFC5zlzMz955z7gw9BC4RgT30lqSUMloKYQtBRK9Gb74UVJAZ5XrHmTvnxBmXHqIf38Ph4//n/33f4QRBEGNIwOMFIo/6Ou6+uX7ndXPvq61y9b5sv/eu59YD2SPJMnS5XBar9XhOLieIEi9IgiCqquqoqnTW1P9Ypd8Wyaf5NVZzodmvS5/nw78JnRgbA5eb3JeuXK2qdxYUFh45xrlFqYcXBVEMBPzFJbbSsxWUUkJ0QnS6ASFRnVD6fmLk/s3W222yUtMklRfV5p7kBIkli6KIVbXaUdl9wxUlNKSFdF0nhFBCljQ9FApTSp8OPemqaupvuzbQ3uksa8kxF7CxWbIgqv7AuXJb88VqPUpDIY2S6KKmr4UjCytry8sapfTR4GDQXfdRLf3Q3xjEgtuezwEAeJGZ2djFtqLiUkKIpmnRSHhhJbwWiVBKdD1CKX0xMdRafaI2N1t1pE23GJ83Z3FAAuxgbOdAcUmJ3W6PbcmglP5cJbO/qPblO52bfzgynHIwM8tk2ZdkNO1P7M5P5HhBdMc+y+/3l9ntZoslOD4+8nh05u3k1LOJ8WBwcmp6tG9gZmiY7+xKO2A8YjqamJgQt3unLX0HJwEgAcAOhrHD4eA4zmAwWBO4rCT2tGfsnamN67DEn66vs5acyczKNGak7onbtX2b4VSKge0MAJAkCULY2NCQlGy056W3VWTEJx+ymA77ss28yWSzmPPLCmvbL9S3ni90FlnzcrKt5tSMzA3zOrIsI4T9GCkQIYwwQn6IBAg9CoQ+BSlKIOBnXYwQhBijv2YpZlYxRhhjxICI6VS8BYIQrbMu4Twe4AHA49lMxnhT8F9gDGbu5tm1u3mRFyWv1+fzyV6f7JNlJQaE8F+noiiyLHu93j/4qqz889fYuAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/396c62abea0556e4984de649ae1a6f49/e4706/1080p_Settings_Features_PTZ_Advanced.avif 230w", "/en/static/396c62abea0556e4984de649ae1a6f49/d1af7/1080p_Settings_Features_PTZ_Advanced.avif 460w", "/en/static/396c62abea0556e4984de649ae1a6f49/7f308/1080p_Settings_Features_PTZ_Advanced.avif 920w", "/en/static/396c62abea0556e4984de649ae1a6f49/b0250/1080p_Settings_Features_PTZ_Advanced.avif 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/396c62abea0556e4984de649ae1a6f49/a0b58/1080p_Settings_Features_PTZ_Advanced.webp 230w", "/en/static/396c62abea0556e4984de649ae1a6f49/bc10c/1080p_Settings_Features_PTZ_Advanced.webp 460w", "/en/static/396c62abea0556e4984de649ae1a6f49/966d8/1080p_Settings_Features_PTZ_Advanced.webp 920w", "/en/static/396c62abea0556e4984de649ae1a6f49/f16bf/1080p_Settings_Features_PTZ_Advanced.webp 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/396c62abea0556e4984de649ae1a6f49/81c8e/1080p_Settings_Features_PTZ_Advanced.png 230w", "/en/static/396c62abea0556e4984de649ae1a6f49/08a84/1080p_Settings_Features_PTZ_Advanced.png 460w", "/en/static/396c62abea0556e4984de649ae1a6f49/c0255/1080p_Settings_Features_PTZ_Advanced.png 920w", "/en/static/396c62abea0556e4984de649ae1a6f49/bb27a/1080p_Settings_Features_PTZ_Advanced.png 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/396c62abea0556e4984de649ae1a6f49/c0255/1080p_Settings_Features_PTZ_Advanced.png",
              "alt": "Web User Interface - 1080p Series - Features PTZ",
              "title": "Web User Interface - 1080p Series - Features PTZ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PTZTable02 mdxType="PTZTable02" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "outdoor-cameras-eg-in-9020-full-hd-in-9010-full-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#outdoor-cameras-eg-in-9020-full-hd-in-9010-full-hd",
        "aria-label": "outdoor cameras eg in 9020 full hd in 9010 full hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outdoor Cameras (e.g. IN-9020 Full HD, IN-9010 Full HD)`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4ad64c241e0b1c9ced95eb0c784d6fb0/0931d/1080p_Settings_Features_PTZ_Pelco_Positions.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAACzUlEQVQoz32RXUhTYRjHj96UYSp+VCszP1o6nfOLFuXUdJbbJMGJH12MljpUxKi18/FuO+e852wLwSWNuojKi7RIygQtiSgl6OM29D5JqMBgUWq6tnPOG+csJLrox3vxvPD8+D/P+2IEQZAKFAC0hyJ9ob7QK8fovD24YA8unBuZtwfn7SMv+66/cfpv0R7AMPB8d3eJVldeUYXhBIUTFEGQPO+zmE3W9q5wBH1Zkz6sbi6vbq2EY8tft1bC0XWEpicnSZvNZbPbm60tx6pPaLSYC6cu4yRBEIGA31BT12g0IYQkKSYJESRG5VKSkCRGEHo3PXH74sCVHre35SzVVGspKccIUk4myXhyk3PIEY0J6+sboigiBUlCCIkff6B7NyfwM7bRfkfQcclUZ9OVHJVll5xM8j6/+dTJflurKKLNnxvRaFRSPAkhIRZDCI2Hrs0RbUtc3ftQzwOPq7e2DAMA4KS8c3zs+vpGAaGNSEyIxQRBkOR8URAFhNDzqbH+5oquKs1Va+7rgZwpWxFGUSD+YIFAwGCoMZlMCK1JW58kSR47vImWv6Nfn1dR+Nud8bt7VXkatTYrU1WWne7SZ2AunMSVz/L7fA0NRq22dHZs+OENeuntk2dzs09nZh7PvHg0PLJ4f/LC4NDBfSr14SNpaSlpyUnG3CSMAoACQHkw3mw2YRiWkJCozcA0WXJpLUxdbEvpLc0wtLdrqvUFhQX787KTdu1M3pF4XJUg7wwUGULY2dmRmr7HUpnjOH1od+YBnbqA1RQDdYFRW6y31LYOdln7OvTm6tJKXaG2KCc//48ch6ZpCKGPgywLWQh5yAZYiLMsoBnGS3MQ+gN+yEEIIcuyHGT/lXmeg5CLIzdxkOc4fvsqOzDeASHE3G7gBsDtlmWvkszC/8EqxGvMiZMugnTiJE5SXq/XIx/aS9OMwnbf3zLDMDRNezye3yi9q4+DvbXQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4ad64c241e0b1c9ced95eb0c784d6fb0/e4706/1080p_Settings_Features_PTZ_Pelco_Positions.avif 230w", "/en/static/4ad64c241e0b1c9ced95eb0c784d6fb0/d1af7/1080p_Settings_Features_PTZ_Pelco_Positions.avif 460w", "/en/static/4ad64c241e0b1c9ced95eb0c784d6fb0/7f308/1080p_Settings_Features_PTZ_Pelco_Positions.avif 920w", "/en/static/4ad64c241e0b1c9ced95eb0c784d6fb0/f056e/1080p_Settings_Features_PTZ_Pelco_Positions.avif 1373w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4ad64c241e0b1c9ced95eb0c784d6fb0/a0b58/1080p_Settings_Features_PTZ_Pelco_Positions.webp 230w", "/en/static/4ad64c241e0b1c9ced95eb0c784d6fb0/bc10c/1080p_Settings_Features_PTZ_Pelco_Positions.webp 460w", "/en/static/4ad64c241e0b1c9ced95eb0c784d6fb0/966d8/1080p_Settings_Features_PTZ_Pelco_Positions.webp 920w", "/en/static/4ad64c241e0b1c9ced95eb0c784d6fb0/082bf/1080p_Settings_Features_PTZ_Pelco_Positions.webp 1373w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4ad64c241e0b1c9ced95eb0c784d6fb0/81c8e/1080p_Settings_Features_PTZ_Pelco_Positions.png 230w", "/en/static/4ad64c241e0b1c9ced95eb0c784d6fb0/08a84/1080p_Settings_Features_PTZ_Pelco_Positions.png 460w", "/en/static/4ad64c241e0b1c9ced95eb0c784d6fb0/c0255/1080p_Settings_Features_PTZ_Pelco_Positions.png 920w", "/en/static/4ad64c241e0b1c9ced95eb0c784d6fb0/0931d/1080p_Settings_Features_PTZ_Pelco_Positions.png 1373w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4ad64c241e0b1c9ced95eb0c784d6fb0/c0255/1080p_Settings_Features_PTZ_Pelco_Positions.png",
              "alt": "Web User Interface - 1080p Series - Features PTZ",
              "title": "Web User Interface - 1080p Series - Features PTZ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PTZTable03 mdxType="PTZTable03" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h4 {...{
      "id": "extended-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#extended-1",
        "aria-label": "extended 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Extended`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d921e03b2e26d0bd697129226400a36b/0931d/1080p_Settings_Features_PTZ_Pelco_Advanced.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAACxklEQVQoz32RbUhTYRTHr34pw1J8qVZmvrR0uk1NNNKp6SS3GYIzX/qwWuaYUhit3Zdn2733uXdbBL7EiL70IpREFmmk5ofKCCroW+R3QYpeiFnJyHTbfZ64dyHRh/4cHs6B8+N/znkIiqJoRQwArJeh/SFn6IVjdN4+PH9ySH7tI8/tQ8+cV165AtdYL+A4eKq3t1SrL6+oJEiKISmGomhR9FvMpo7OnuU1/GkFLYXXl8LRpfD64uef779Ff0h4amKCPmFz2+z2VmvbwdoajZZwk8wFkqYoKhgMGOoamo0mjDFCMYTiGGMsxXDsF5JiGOM3D8evnxu42OfxtR1nWuotpeUERcvONJ1wbnENOiQJRSKRaDSKEEIYxSQUWY1ijKdu3nEdtV3udww7zpsabPrSKhl2y8606A+Ymw/329plPykuSUgZQY54THa+HQo9pjoWhIa3odN3ve6++jICAEDS8s6JsRsbmzHGcYQkRTKKsRSXV3gyOeZsreip1IxY814O5D6wFRMMAxIHCwaDBkOdyWTCeAWtfUQKtryKF7/j9Q9f8NfwjfFbO1T5GrU2O0tVlpPhrs4k3CRNKp8V8PubmoxarXZ67NK9q8LC69m52UdzM9PTM08nh0cX7k8Onh3cs1OlVu9PT9+WnppizEshGAAYAJSDiWaziSCIpORkXSahyZZTa1Hau460Pl2mofOYpqaqsKhwV35OypbNqZuSD6mS5J2BAkMIu7u70jK2WypzHUf2bs3arVcX8poSoN5n1JVUW+rbz/RYnV3V5lrdAX2Rtji3oOAPnBDLshBCvwB5HvIQipAP8pDkecBynI8VIAwEA1CAEEKe5wXI/wuLogChkJDcJEBREMSNUmZgogNCSHg8wAOAxyPDPsWZh/8TryiREy6SdlO0i6RJmvH5fF45WB/Lcoo2+v6GOY5jWdbr9f4GSZGsGcf14OQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d921e03b2e26d0bd697129226400a36b/e4706/1080p_Settings_Features_PTZ_Pelco_Advanced.avif 230w", "/en/static/d921e03b2e26d0bd697129226400a36b/d1af7/1080p_Settings_Features_PTZ_Pelco_Advanced.avif 460w", "/en/static/d921e03b2e26d0bd697129226400a36b/7f308/1080p_Settings_Features_PTZ_Pelco_Advanced.avif 920w", "/en/static/d921e03b2e26d0bd697129226400a36b/f056e/1080p_Settings_Features_PTZ_Pelco_Advanced.avif 1373w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d921e03b2e26d0bd697129226400a36b/a0b58/1080p_Settings_Features_PTZ_Pelco_Advanced.webp 230w", "/en/static/d921e03b2e26d0bd697129226400a36b/bc10c/1080p_Settings_Features_PTZ_Pelco_Advanced.webp 460w", "/en/static/d921e03b2e26d0bd697129226400a36b/966d8/1080p_Settings_Features_PTZ_Pelco_Advanced.webp 920w", "/en/static/d921e03b2e26d0bd697129226400a36b/082bf/1080p_Settings_Features_PTZ_Pelco_Advanced.webp 1373w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d921e03b2e26d0bd697129226400a36b/81c8e/1080p_Settings_Features_PTZ_Pelco_Advanced.png 230w", "/en/static/d921e03b2e26d0bd697129226400a36b/08a84/1080p_Settings_Features_PTZ_Pelco_Advanced.png 460w", "/en/static/d921e03b2e26d0bd697129226400a36b/c0255/1080p_Settings_Features_PTZ_Pelco_Advanced.png 920w", "/en/static/d921e03b2e26d0bd697129226400a36b/0931d/1080p_Settings_Features_PTZ_Pelco_Advanced.png 1373w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d921e03b2e26d0bd697129226400a36b/c0255/1080p_Settings_Features_PTZ_Pelco_Advanced.png",
              "alt": "Web User Interface - 1080p Series - Features PTZ",
              "title": "Web User Interface - 1080p Series - Features PTZ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PTZTable04 mdxType="PTZTable04" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      